<template>
  <div class="landing-page">
    <div class="container">
      <AdminHeader />

      <h2 class="heading">Startseite</h2>

      <p class="body-1">
        Mithilfe von dieser Web-Applikation kannst du Gutscheine, die du von der
        Redaktion der FHG News erhalten hast, einlösen. Um deinen digitalen
        Gutschein zu öffnen, musst du lediglich auf den Link klicken, den wir
        dir gesendet haben, oder im Textfeld die ID Nummer deines Gutscheins
        eingeben.
      </p>

      <v-form
        class="idForm"
        ref="idForm"
        v-model="valid"
        @submit.prevent="submitIdForm"
        @keyup.enter="submitIdForm"
      >
        <v-text-field
          outlined
          class="idField"
          label="ID Nummer"
          v-model="idNumber"
          :rules="[
            (value) => value.trim().length > 0 || 'Dies ist ein Pflichtfeld',
            (value) =>
              (/^\d+$/.test(value) && value.length <= 14) ||
              'Gib eine 14-stellige Nummer ein',
          ]"
          :error-messages="idInvalidMessage"
          @input="idInvalidMessage = ''"
          counter="14"
          type="number"
        ></v-text-field>
        <v-btn elevation="0" class="submitBtn" type="submit" color="primary"
          >Gutschein aufrufen</v-btn
        >
      </v-form>
    </div>
  </div>
</template>
<script>
import AdminHeader from '@/components/AdminHeader';

export default {
  name: 'Landing',
  components: {
    AdminHeader,
  },
  data() {
    return { valid: false, idNumber: '', idInvalidMessage: '' };
  },
  methods: {
    submitIdForm() {
      let idForm = this.$refs.idForm;
      if (!idForm) return;

      let valid = idForm.validate();
      if (!valid) return;

      if (this.idNumber.length !== 14)
        return (this.idInvalidMessage = 'ID Nummer muss 14-stellig sein');

      this.$router.push({
        path: `/${this.idNumber}`,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.landing-page {
  padding: 32px 16px 0;
  font-size: 14px;
  min-height: 100vh;
  background-color: #fafafa;

  .container {
    padding: 0;
    max-width: 800px;
    display: block;
    margin: 0 auto;

    p {
      margin: 0;
    }

    .heading {
      font-size: 18px;
      font-weight: 500;
      color: #000000;
      margin: 0 0 12px 0;
    }

    .idForm {
      margin: 32px 24px;

      .idField {
        &::v-deep input {
          letter-spacing: 4px;
        }
      }

      .submitBtn {
        margin: 8px 0 0 0;
      }
    }
  }
}
</style>
